export default [
    // {
    //     title: 'Incidencias',
    //     icon: 'AlertOctagonIcon',
    //     route: 'see_incidencies',
    //     permission: 'see_all_interventions_gmao',
    // },
    // {
    //     title: 'Incidencias',
    //     icon: 'AlertOctagonIcon',
    //     route: 'see_incidencies',
    //     permission: 'see_interventions_assigned_gmao',
    // },
]
